import React from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <NavLink to="/">
      <h1 className="w-1/3 text-center font-incopins text-primary text-6xl tracking-widest pb-2">
        BOX<span className="text-secondary">FI</span><span className="text-accent">SH</span>
      </h1>
      </NavLink>
    </div>
  );
};

export default Header;
