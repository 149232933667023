const convertToHtml = (date, winTitle, paragraphs, image) => {
    const formattedDate = date.toLocaleDateString('it-IT');
    const paragraph = paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('\n');
    const htmlText = `
    <!DOCTYPE html>
    <html lang="it">
      <head>
        <meta charset="UTF-8" />
        <meta
          name="viewport"
          id="viewport"
          content="width=device-width, user-scalable=no, minimum-scale=1, maximum-scale=1"
        />
        <title>Top vincite</title>
        <script>
          var dateVersion = new Date().getTime();
          console.log("Date Version:", dateVersion);
    
          var script = document.createElement("script");
          script.src = "../../assets/js/script.js?" + dateVersion;
          script.type = "text/javascript";
    
          var link = document.createElement("link");
          link.href = "../../assets/css/template.css?" + dateVersion;
          link.rel = "stylesheet";
          link.type = "text/css";
    
          document.head.appendChild(script);
          document.head.appendChild(link);
    
          function ready() {
            parent.postMessage({ type: "iframeLoaded" }, "*");
          }
        </script>
        <link rel="stylesheet" href="assets/css/top_vincite.css" />
        <!--<script src="/mylotteries/app/info/assets/js/jquery-ui.min.js"></script>
    <script src="/mylotteries/app/info/assets/js/jquery-3.2.0.min.js"></script>-->
        <style>
          .picture img {
            width: 100%;
            height: auto;
          }
          .wins_title {
            font-weight: bold;
            margin: 8px 0;
            font-size: 16px;
            line-height: 18px;
          }
          .date {
            /*float: right;*/
            font-size: 15px;
            margin: 5px 0;
          }
        </style>
      </head>
    
      <body onload="ready()">
        <div class="template-wrapper" id="templateWrapper">
          <header class="header-default">
            <div class="head_title">
              <i class="back" id="backIcon"></i>
              <h1>Top vincite</h1>
            </div>
          </header>
          <div class="template-container">
            <!----  Banner Template --------->
            <!--<div class="banner-generic" style="background-image: url(../../assets/img/newsInfo/banners/banner_news.jpg);">
            </div>-->
    
            <!----  Paragraph Template --------->
            <div class="template-section">
              <!--GeV-->
              <div class="wins">
                <div class="picture">
                  <img src="./assets/images/top-vincite/${image}" />
                </div>
                <div class="date">
                  <i class="fa fa-calendar-check-o"></i>${formattedDate}
                </div>
                <h4 class="wins_title">${winTitle}</h4>
                <p style="clear: both"></p>
                <div class="wins_text">
                  ${paragraph}
                  <div class="flex-center">
                    <a
                      data-deeplink="true"
                      href="vetrinaGV"
                      class="but but_blue but_40h but_regular but_10elotto"
                      >GIOCA</a
                    >
                  </div>
                </div>
              </div>
              <!--/GeV-->
            </div>
          </div>
          <footer id="footer_generic_pages"></footer>
        </div>
      </body>
    </html>
    
    `;
  
    return htmlText;
  };
  
  export { convertToHtml };
  