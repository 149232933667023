import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <h2 className="text-2xl my-10 mx-96 rounded-lg p-6 uppercase text-white bg-error ">
        <b>
          page not found
          <br /> you are being redirected to the home page
        </b>
      </h2>
    </div>
  );
};

export default NotFound;
