import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DateInputField({
  date,
  label,
  handleChange,
  height,
  width,
  inputName,
  rightLabel,
}) {
  return (
    <div className="form-control m-2">
      <label className="label">
        <span className="label-text">{inputName}</span>
        <span className="label-text-alt">{rightLabel}</span>
      </label>
      <DatePicker
        selected={date}
        onChange={handleChange}
        dateFormat="dd/MM/yyyy"
        style={{ height: height, width: width }}
        className={`input input-bordered ${width} bg-neutral`}
        label={label}
      />
    </div>
  );
}

export default DateInputField;
