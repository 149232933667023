const applyBold = (paragraphs, setParagraphs, setWinTitle, winTitle) => {
    const selectedText = window.getSelection().toString();

    if (selectedText) {
      const boldText = `<strong>${selectedText}</strong>`;
      const updatedParagraphs = paragraphs.map((text) =>
        text.replace(selectedText, boldText)
      );
      setParagraphs(updatedParagraphs);
      setWinTitle(winTitle.replace(selectedText, boldText));
    }
  };

  const handleKeyDown = (e, paragraphs, setParagraphs, setWinTitle, winTitle) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'b') {
      e.preventDefault();
      applyBold(paragraphs, setParagraphs, setWinTitle, winTitle);
    }
  };


  const addParagraph = (setParagraphs, paragraphs) => {
    setParagraphs([...paragraphs, ""]);
  };

// -------TO REFACTOR--------
//   const paragraphChange = (text, index, paragraphs, setParagraphs) => {
//     const updatedParagraphs = [...paragraphs];
//     updatedParagraphs[index] = text;
//     setParagraphs(updatedParagraphs);
//   };

//   const handleFilenameChange = (e, setFilename) => {
//     setFilename(e.target.value);
//   };

  const textFunctions = {
    applyBold,
    addParagraph,
    handleKeyDown
    // paragraphChange,
    // handleFilenameChange
  }

  export default textFunctions