import React from "react";

function InputField({
  inputValue,
  handleChange,
  label,
  height,
  width,
  inputName,
  rightLabel,
}) {
  return (
    <div className="form-control m-2">
      <label className="label">
        <span className="label-text">{inputName}</span>
        <span className="label-text-alt">{rightLabel}</span>
      </label>
      <input
        type="text"
        placeholder={label}
        value={inputValue}
        onChange={handleChange}
        className={`input input-bordered ${width} bg-neutral`}
      />
    </div>
  );
}

export default InputField;
