import React from "react";

function ParagraphEditor({ index, text, handleParagraphChange }) {
  return (
    <div className="form-control m-2">
      <textarea
        placeholder={`Paragraph ${index + 1}`}
        className="textarea textarea-bordered textarea-lg bg-neutral h-48"
        value={text}
        onChange={handleParagraphChange}
      />
    </div>
  );
}

export default ParagraphEditor;
