import { useState, useRef, useEffect } from "react";
import { encodeSpecialCharacters } from "../utils/encoder";
import textFunctions from "./textUtils";

const useGenerator = (initialImage, initialFilename, convertToHtmlTemplate) => {
  const [html, setHtml] = useState({ __html: "" });
  const [date, setDate] = useState(new Date());
  const [winTitle, setWinTitle] = useState("");
  const [paragraphs, setParagraphs] = useState([""]);
  const [image, setImage] = useState(initialImage);
  const [filename, setFilename] = useState(initialFilename);
  const downloadLinkRef = useRef();

  useEffect(() => {
    const onKeyDown = (e) =>
      textFunctions.handleKeyDown(e, paragraphs, setParagraphs, setWinTitle, winTitle);

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [paragraphs, setParagraphs, setWinTitle, winTitle]);

  const refreshPage = () => {
    window.location.reload();
  };

  const handleBoldClick = () => {
    textFunctions.applyBold(paragraphs, setParagraphs, setWinTitle, winTitle);
  };

  const handleAddParagraph = () => {
    textFunctions.addParagraph(setParagraphs, paragraphs);
  };

  const handleParagraphChange = (text, index) => {
    const updatedParagraphs = [...paragraphs];
    updatedParagraphs[index] = text;
    setParagraphs(updatedParagraphs);
  };

  const handleFilenameChange = (e) => {
    setFilename(e.target.value);
  };

  const downloadHtmlFile = (content, filename) => {
    const blob = new Blob([content], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    downloadLinkRef.current.href = url;
    downloadLinkRef.current.download = filename;
    downloadLinkRef.current.click();
    URL.revokeObjectURL(url);
  };

  const handleConvertToHtml = () => {
    const encodedWinTitle = encodeSpecialCharacters(winTitle);
    const encodedParagraphs = paragraphs.map((text) => encodeSpecialCharacters(text));
    const htmlText = convertToHtmlTemplate(date, encodedWinTitle, encodedParagraphs, image);
    setHtml({ __html: htmlText });
    downloadHtmlFile(htmlText, `${filename}.html`);
  };

  return {
    date,
    setDate,
    winTitle,
    setWinTitle,
    paragraphs,
    image,
    setImage,
    filename,
    handleFilenameChange,
    handleBoldClick,
    handleAddParagraph,
    handleConvertToHtml,
    handleParagraphChange,
    refreshPage,
    downloadLinkRef,
    html,
  };
};

export default useGenerator;
