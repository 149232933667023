/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DOMPurify from 'dompurify';
import useGenerator from "../utils/useGenerator";
import { convertToHtml as lottoConvertToHtml } from "../templates/Lotto";
import DateInputField from "../components/DatePickerField";
import InputField from "../components/InputField";
import ParagraphEditor from "../components/ParagraphEditor";
import BoldButton from "../components/BoldButton";

const LottoGenerator = () => {
  const {
    date,
    setDate,
    winTitle,
    setWinTitle,
    paragraphs,
    image,
    setImage,
    filename,
    handleFilenameChange,
    handleBoldClick,
    handleAddParagraph,
    handleConvertToHtml,
    handleParagraphChange,
    refreshPage,
    downloadLinkRef,
    html,
  } = useGenerator("", "top-vincite-lotto", lottoConvertToHtml);

  const sanitizedHtml = DOMPurify.sanitize(html.__html);

  return (
    <div className="w-full">
      <div className="input-data flex">
        <InputField
          inputValue={filename}
          handleChange={handleFilenameChange}
          label="Filename"
          inputName="File name"
          width="w-50"
        />
        <InputField
          inputValue={image}
          handleChange={(e) => setImage(e.target.value)}
          label="Image"
          inputName="Image name. Extension required"
          width="w-80"
        />
        <DateInputField
          date={date}
          handleChange={(date) => setDate(date)}
          label="Date"
          inputName="Date"
          width="w-28"
        />
        <h2 className="mt-12 ml-4 text-2xl uppercase">lotto</h2>
      </div>
      <div className="input-text relative">
        <InputField
          inputValue={winTitle}
          handleChange={(e) => setWinTitle(e.target.value)}
          label="Win Title"
          width="w-25"
        />
        {paragraphs.map((text, index) => (
          <ParagraphEditor
            key={index}
            index={index}
            text={text}
            handleParagraphChange={(e) =>
              handleParagraphChange(e.target.value, index)
            }
          />
        ))}
        <BoldButton applyBold={handleBoldClick} />
      </div>
      <div className="buttons flex justify-between">
        <div>
          <button
            className="btn btn-secondary m-1"
            onClick={handleAddParagraph}
          >
            Add Paragraph
          </button>
          <button className="btn btn-accent m-1" onClick={handleConvertToHtml}>
            Generate
          </button>
        <a ref={downloadLinkRef} style={{ display: "none" }}>
          Download Link
        </a> 
        </div>
        <button
          className="btn btn-primary m-1 justify-end"
          onClick={refreshPage}
        >
          Clear up
        </button>
      </div>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
    </div>
  );
};

export default LottoGenerator;
