export function encodeSpecialCharacters(text) {
    const encodedCharacters = {
      "à": "&agrave;",
      "è": "&egrave;",
      "é": "&eacute;",
      "ì": "&igrave;",
      "ò": "&ograve;",
      "ù": "&ugrave;",
      "'": "&#39;", // Apostrophe
      '"': "&quot;", // Double quotes
      '“': "&ldquo;", // Left double quotation mark
      '”': "&rdquo;", // Right double quotation mark
      '‘': "&lsquo;", // Left single quotation mark
      '’': "&rsquo;", // Right single quotation mark
      '€': "&euro;",  // Euro symbol
    };
  
    return text.replace(/[àèéìòù'"“”‘’€]/g, (char) => encodedCharacters[char] || char);
  }