import React from "react";

const Home = () => {
  return (
    <div>
      <h2 className="text-2xl my-10 mx-96 rounded-lg p-6 uppercase text-white bg-primary ">
        <b>Select one of the generators above</b>
      </h2>
    </div>
  );
};

export default Home;
