/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Route, Routes, NavLink } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import Home from "./routes/Home";
import SimbolottoGenerator from "./routes/SimbolottoGenerator";
import DieciELottoGenerator from "./routes/DieciELottoGenerator";
import GevGenerator from "./routes/GevGenerator";
import LottoGenerator from "./routes/LottoGenerator";
import MillionDayGenerator from "./routes/MillionDayGenerator";
import NotFound from "./routes/NotFound";
import Header from "./components/Header";

import "./styles/App.css";

const App = () => {
  return (
    <div className="App">
      <Header />
      <nav className="products">
        <ul className="flex">
          <NavLink to="/simbolotto">
            <li className="btn btn-neutral m-1">
              <span className="text-orange-500">simbo</span>
              <span className="text-blue-500">lotto</span>
            </li>
          </NavLink>
          <NavLink to="/10elotto">
            <li className="btn bg-blue-400 hover:bg-blue-600 m-1">
              <span className="text-white">10eLotto</span>
            </li>
          </NavLink>
          <NavLink to="/gev">
            <li className="btn m-1 bg-yellow-400 hover:bg-yellow-500">GEV</li>
          </NavLink>
          <NavLink to="/lotto">
            <li className="btn m-1 bg-orange-400 hover:bg-orange-600">
              <span className="text-white">Lotto</span>
            </li>
          </NavLink>
          <NavLink to="/millionday">
            <li className="btn m-1 bg-teal-300 hover:bg-teal-400">
              <span>MillionDay</span>
            </li>
          </NavLink>
      <div className="flex flex-col items-start">
        <p>HTML: info/pages/generiche</p>
        <p>Image: ./assets/images/top-vincite</p>
      </div>
        </ul>
      </nav>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/simbolotto" element={<SimbolottoGenerator />} />
        <Route exact path="/10elotto" element={<DieciELottoGenerator />} />
        <Route exact path="/gev" element={<GevGenerator />} />
        <Route exact path="/lotto" element={<LottoGenerator />} />
        <Route exact path="/millionday" element={<MillionDayGenerator />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <p className="text-xs mt-20">Copyright © Giovanni Tafone, 2023 - All right reserved</p>
    </div>
  );
};

export default App;
