import React from "react";

function BoldButton({ applyBold }) {
  return (
    <button
      className="btn btn-primary absolute top-20 left-24"
      onClick={applyBold}
    >
      Bold
    </button>
  );
}

export default BoldButton;
